import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Stack from "../components/stack";
import TimelineComponent from "../components/timeline";
import { motion } from "framer-motion";
import Cookies from "js-cookie";

const Index = () => {
  const [language, setLanguage] = useState(Cookies.get("language") || "en");

  useEffect(() => {
    Cookies.set("language", language, { expires: 365 });
  }, [language]);

  useEffect(() => {
    const interval = setInterval(() => {
      const cookieLanguage = Cookies.get("language");
      if (cookieLanguage && cookieLanguage !== language) {
        setLanguage(cookieLanguage);
      }
    }, 1);

    return () => clearInterval(interval);
  }, [language]);

  const handleLanguageToggle = () => {
    const newLanguage = language === "en" ? "de" : "en";
    setLanguage(newLanguage);
    Cookies.set("language", newLanguage, { expires: 365 });
  };

  return (
    <div className="flex flex-col min-h-screen text-white bg-gray-800">
      <Header onLanguageToggle={handleLanguageToggle} language={language} />
      <main className="flex-grow p-8">
        <section className="flex justify-center mb-14">
          <div className="w-full max-w-2xl text-left">
            <motion.h1
              className="text-5xl md:text-7xl font-bold"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              Dennis Borisov
            </motion.h1>
            <p className="text-lg md:text-xl mb-8">
              {language === "en"
                ? "a software developer from Germany"
                : "ein Softwareentwickler aus Deutschland"}
            </p>
          </div>
        </section>

        <TimelineComponent />
        <Stack />
      </main>
      <Footer />
    </div>
  );
};

export default Index;