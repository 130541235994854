import React, { useState } from "react";

const CreationCard = ({ image, title, description, imagePosition = "left", url}) => {
  const [isHovered, setIsHovered] = useState(false);
  const isImageLeft = imagePosition === "left";

  return (
    <div
      className={`relative bg-gray-900 p-5 rounded-2xl shadow-md shadow-black transition-transform duration-500 ease-in-out ${isHovered ? 'transform scale-105 cursor-pointer' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {window.location.href = url}}
    >
      <div className={`flex ${isImageLeft ? 'flex-row' : 'flex-row-reverse'} items-center`}>
        <div className={`relative w-1/2 overflow-hidden ${isHovered ? 'z-10' : ''}`}>
          <img
            src={image}
            alt={title}
            className={`w-full h-48 object-cover rounded-lg transition-transform duration-500 ease-in-out ${isHovered ? 'transform scale-125' : ''}`}
          />
        </div>
        <div className={`relative w-1/2 p-5 ${isImageLeft ? 'pl-5' : 'pr-5'} z-20`}>
          <h3 className={`text-xl font-semibold mb-2 text-white ${isHovered ? 'underline' : ''}`}>
            {title}
          </h3>
          <p className="text-gray-400">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreationCard;
