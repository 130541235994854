import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import {
  Typography,
  Alert,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";

const fetchUserData = async () => {
  const session_id = Cookies.get("session");

  if (!session_id) {
    window.location.href = "/login";
    return;
  }

  try {
    const response = await fetch("https://api.noemt.dev/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ session_id }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    throw error;
  }
};

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUserData();
        setUserData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen bg-gray-800 text-gray-200">
        <Header />
        <main className="flex-grow p-8">
        </main>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-800">
        <Header />
        <main className="flex-grow p-8">
          <Alert severity="error">Error: {error}</Alert>
        </main>
        <Footer />
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-800">
        <Header />
        <main className="flex-grow p-8">
          <Typography>No user data found</Typography>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-800 text-gray-200">
      <Header />
      <main className="flex-grow p-8"></main>
      <Footer />
    </div>
  );
};

export default Dashboard;
