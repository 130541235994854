import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Cookies from 'js-cookie';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import CustomSnackbar from "../components/snackbar";

const RegisterLoginPage = () => {
  const [isRegister, setIsRegister] = useState(true);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: ''
  });

  const [isVerified, setIsVerified] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: '', open: false, severity: 'error' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!isVerified) {
      setSnackbar({ message: "Please complete the CAPTCHA.", open: true, severity: 'error' });
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setSnackbar({ message: "Passwords do not match!", open: true, severity: 'error' });
      return;
    }

    try {
      const response = await fetch('https://api.noemt.dev/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      let data = await response.json();

      if (response.ok) {
        console.log('User registered successfully');
        setSnackbar({ message: 'User registered successfully', open: true, severity: 'success' });

        Cookies.set('session', data.session, { expires: 1 });
        Cookies.set('username', formData.username, { expires: 1 });
        window.location.href = '/dashboard';

      } else {
        console.error('Failed to register user');
        setSnackbar({ message: data.message, open: true, severity: 'error' });
      }
    } catch (error) {
      console.error('Failed to register user:', error);
      setSnackbar({ message: 'Failed to register user', open: true, severity: 'error' });
    }

    setFormData({
      username: '',
      password: '',
      confirmPassword: ''
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.noemt.dev/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      let data = await response.json();

      if (response.ok) {
        console.log('User logged in successfully');
        setSnackbar({ message: 'Login successful!', open: true, severity: 'success' });

        Cookies.set('session', data.session, { expires: 1 });
        Cookies.set('username', formData.username, { expires: 1 });
        window.location.href = '/dashboard';

      } else {
        console.error('Failed to log in');
        setSnackbar({ message: data.message, open: true, severity: 'error' });
      }
    } catch (error) {
      console.error('Failed to log in:', error);
      setSnackbar({ message: 'Failed to log in', open: true, severity: 'error' });
    }

    setFormData({
      username: '',
      password: '',
      confirmPassword: ''
    });
  };

  const toggleForm = () => {
    setIsRegister(!isRegister);
  };

  const onCaptchaVerify = (token) => {
    setIsVerified(true);
  };

  const onCaptchaError = (error) => {
    console.error('Captcha error:', error);
    setSnackbar({ message: 'Captcha error occurred. Please try again.', open: true, severity: 'error' });
    setIsVerified(false);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ message: '', open: false, severity: 'error' });
  };

  return (
    <div className="flex flex-col min-h-screen text-white bg-gray-800">
      <Header />
      <main className="container mx-auto flex-grow px-8 py-12">
        <section className="text-center mb-12">
          <h1 className="text-5xl font-bold mb-4">{isRegister ? "Register" : "Login"}</h1>
          <p className="text-xl mb-8">Please {isRegister ? "create an account" : "login to your account"}</p>
          <div className="flex justify-center mb-8">
            <button
              onClick={toggleForm}
              className={`px-6 py-2 rounded-l-lg ${isRegister ? 'bg-blue-600' : 'bg-gray-700'} hover:bg-blue-700 transition duration-300`}
            >
              Register
            </button>
            <button
              onClick={toggleForm}
              className={`px-6 py-2 rounded-r-lg ${!isRegister ? 'bg-blue-600' : 'bg-gray-700'} hover:bg-blue-700 transition duration-300`}
            >
              Login
            </button>
          </div>
        </section>
        <section className="flex justify-center">
          <div className="bg-gray-700 p-8 rounded-lg shadow-lg w-full max-w-md">
            {isRegister ? (
              <form onSubmit={handleRegister}>
                <div className="mb-4">
                  <label className="block text-lg mb-2" htmlFor="username">Username</label>
                  <input
                    className="w-full px-3 py-2 text-gray-900 rounded-lg"
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-lg mb-2" htmlFor="password">Password</label>
                  <input
                    className="w-full px-3 py-2 text-gray-900 rounded-lg"
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    minLength="8"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-lg mb-2" htmlFor="confirm-password">Confirm Password</label>
                  <input
                    className="w-full px-3 py-2 text-gray-900 rounded-lg"
                    type="password"
                    id="confirm-password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                    minLength="8"
                  />
                </div>
                <div className="mb-4 flex items-center justify-center">
                  <HCaptcha
                    sitekey="31766c7c-891a-4f80-9bda-5824c4e87e4a"
                    onVerify={onCaptchaVerify}
                    onError={onCaptchaError}
                  />
                </div>
                <button
                  type="submit"
                  className="w-full px-6 py-3 bg-blue-600 text-white text-xl rounded-lg hover:bg-blue-700 transition duration-300"
                >
                  Register
                </button>
              </form>
            ) : (
              <form onSubmit={handleLogin}>
                <div className="mb-4">
                  <label className="block text-lg mb-2" htmlFor="username">Username</label>
                  <input
                    className="w-full px-3 py-2 text-gray-900 rounded-lg"
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-lg mb-2" htmlFor="password">Password</label>
                  <input
                    className="w-full px-3 py-2 text-gray-900 rounded-lg"
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    minLength="8"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full px-6 py-3 bg-blue-600 text-white text-xl rounded-lg hover:bg-blue-700 transition duration-300"
                >
                  Login
                </button>
              </form>
            )}
          </div>
        </section>
      </main>
      <Footer />
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        onClose={handleSnackbarClose}
        severity={snackbar.severity}
      />
    </div>
  );
};

export default RegisterLoginPage;