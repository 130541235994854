import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Cookies from "js-cookie";
import translations from "../translations";

const calculateMonthsUntilDate = (date) => {
  const now = new Date();
  const futureDate = new Date(date);

  const yearsDiff = futureDate.getFullYear() - now.getFullYear();
  const monthsDiff = futureDate.getMonth() - now.getMonth();

  return yearsDiff * 12 + monthsDiff;
};

const graduationDate = new Date("2025-06-01");
const monthsUntilGraduation = calculateMonthsUntilDate(graduationDate);

const TimelineComponent = () => {
  const [language, setLanguage] = useState(Cookies.get("language") || "en");

  useEffect(() => {
    const interval = setInterval(() => {
      const cookieLanguage = Cookies.get("language");
      if (cookieLanguage && cookieLanguage !== language) {
        setLanguage(cookieLanguage);
      }
    }, 1); // Check every millisecond

    return () => clearInterval(interval);
  }, [language]);

  const t = translations[language];

  return (
    <div>
      <h1 className="text-center text-2xl">{t.title}</h1>
      <section className="flex justify-center">
        <div className="w-full max-w-2xl">
          <Timeline position="alternate">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className="text-blue-500" />
                <TimelineConnector className="bg-blue-500" />
              </TimelineSeparator>
              <TimelineContent className="text-left flex flex-col">
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                >
                  <h3 className="text-2xl text-blue-500">
                    {t.primarySchool}{" "}
                    <span className="text-sm text-gray-400">{t.primarySchoolPeriod}</span>
                  </h3>
                  <p className="text-md mt-2">{t.primarySchoolDescription}</p>
                </motion.div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className="text-green-500" />
                <TimelineConnector className="bg-green-500" />
              </TimelineSeparator>
              <TimelineContent className="text-left flex flex-col">
                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                >
                  <h3 className="text-2xl text-green-500">
                    {t.secondarySchool}{" "}
                    <span className="text-sm text-gray-400">{t.secondarySchoolPeriod(monthsUntilGraduation)}</span>
                  </h3>
                  <p className="text-md mt-2">{t.secondarySchoolDescription}</p>
                </motion.div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className="text-yellow-500" />
                <TimelineConnector className="bg-yellow-500" />
              </TimelineSeparator>
              <TimelineContent className="text-left flex flex-col">
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                >
                  <h3 className="text-2xl text-yellow-500">
                    {t.learnedPython}{" "}
                    <span className="text-sm text-gray-400">{t.learnedPythonPeriod}</span>
                  </h3>
                  <p className="text-md mt-2">{t.learnedPythonDescription}</p>
                </motion.div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className="text-teal-500" />
                <TimelineConnector className="bg-teal-500" />
              </TimelineSeparator>
              <TimelineContent className="text-left flex flex-col">
                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                >
                  <h3 className="text-2xl text-teal-500">
                    {t.learnedJavaScript}{" "}
                    <span className="text-sm text-gray-400">{t.learnedJavaScriptPeriod}</span>
                  </h3>
                  <p className="text-md mt-2">{t.learnedJavaScriptDescription}</p>
                </motion.div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className="text-red-500" />
                <TimelineConnector className="bg-red-500" />
              </TimelineSeparator>
              <TimelineContent className="text-left flex flex-col">
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                >
                  <h3 className="text-2xl simban3">
                    {t.internship}{" "}
                    <span className="text-sm text-gray-400">{t.internshipPeriod}</span>
                  </h3>
                  <p className="text-md mt-2">{t.internshipDescription}</p>
                </motion.div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className="text-orange-500" />
              </TimelineSeparator>
              <TimelineContent className="text-left flex flex-col">
                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                >
                  <h3 className="text-2xl text-orange-500">
                    {t.graduation}{" "}
                    <span className="text-sm text-gray-400">
                      {t.graduationPeriod(monthsUntilGraduation)}
                    </span>
                  </h3>
                  <p className="text-md mt-2">{t.graduationDescription}</p>
                </motion.div>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
      </section>
    </div>
  );
};

export default TimelineComponent;