import React from "react";
import { FaCopyright } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const links = [
    //{ href: "/privacy-policy", label: "Privacy Policy" },
    //{ href: "/terms-of-service", label: "Terms of Service" },
    { href: "mailto://hi@dennis.beer", label: "hi@dennis.beer" },
    { href: "https://github.com/noemtdotdev", label: "GitHub" },
    { href: "https://www.linkedin.com/in/dennis-borisov-bb429a309/", label: "LinkedIn" },
  ];

  return (
    <footer className="rounded-lg m-4 bg-gray-900 text-gray-400">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <a
            href="/"
            className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img src="/assets/icon.webp" className="h-8" alt="logo" />
            <span className="text-2xl hover:underline">noemt studios</span>
          </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm sm:mb-0">
            {links.map((link) => (
              <li key={link.href} className="me-4 md:me-6">
                <a href={link.href} className="hover:underline">
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <hr className="my-6 border-gray-600 sm:mx-auto lg:my-8" />
        <div className="text-sm sm:text-center">
          <span className="inline-flex items-center">
            <FaCopyright />
            <span className="ml-1">
              {currentYear}&nbsp;
              <a href="/" className="hover:underline">
                nom
              </a>
            </span>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
