import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NotFoundPage from "./pages/404";
import Index from "./pages/index";
import Creations from "./pages/creations";
import RegisterLoginPage from "./pages/registerLogin";
import Dashboard from "./pages/dashboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/creations" element={<Creations />} />
        <Route path="/login" element={<RegisterLoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
