import React from "react";

const translations = {
  en: {
    title: "Steps in my time",
    primarySchool: "Primary School",
    primarySchoolPeriod: "(2014-2018)",
    primarySchoolDescription: (
      <>
        I attended the{" "}
        <a href="https://diesterweggs.de" className="diesterweg-grundschule">
          Diesterweg-Grundschule
        </a>{" "}
        in Auerbach, where I found my interest in maths.
      </>
    ),
    secondarySchool: "Secondary School",
    secondarySchoolPeriod: (monthsUntilGraduation) =>
      `(2018-${monthsUntilGraduation >= 0 ? "now" : "2025"})`,
    secondarySchoolDescription: (
      <>
        Currently studying at{" "}
        <a href="https://goethegymnasium.com/" className="goethe-gymnasium">
          Goethe-Gymnasium
        </a>{" "}
        in Auerbach.
      </>
    ),
    learnedPython: "Learned Python",
    learnedPythonPeriod: "(2020)",
    learnedPythonDescription: (
      <>
        During the 2020 lockdown, I picked up{" "}
        <a href="https://python.org" className="python">
          Python
        </a>
        , which sparked my interest in programming and software development.
      </>
    ),
    learnedJavaScript: "Learned JavaScript",
    learnedJavaScriptPeriod: "(2023)",
    learnedJavaScriptDescription: (
      <>
        In 2023, I began learning{" "}
        <a href="https://javascript.com" className="javascript">
          JavaScript
        </a>{" "}
        to further enhance my programming skills and explore web development.
      </>
    ),
    internship: "Internship at Simba n³",
    internshipPeriod: "(2024)",
    internshipDescription: (
      <>
        Completed an internship where I had the chance to validate my skills in
        software development by tackling challenging projects. With this
        internship, I enhanced my{" "}
        <a href="https://www.java.com/en/" className="java">
          Java
        </a>{" "}
        skills and learned to work with{" "}
        <a href="https://www.typescriptlang.org/" className="typescript">
          TypeScript
        </a>
        , and{" "}
        <a href="https://angular.dev/" className="angular">
          Angular.js
        </a>
        .
      </>
    ),
    graduation: "Graduation",
    graduationPeriod: (monthsUntilGraduation) =>
      `${
        monthsUntilGraduation >= 0
          ? `(In ${monthsUntilGraduation} months)`
          : "(June 2025)"
      }`,
    graduationDescription: (
      <>
        I'm set to graduate from Goethe-Gymnasium in June 2025. After
        graduating, I plan to apply for an apprenticeship at{" "}
        <a href="https://nhochdrei.de" className="simban3">
          Simba n³
        </a>{" "}
        to continue growing my skillset and start my professional career.
      </>
    ),
  },
  de: {
    title: "Schritte in meinem Leben",
    primarySchool: "Grundschule",
    primarySchoolPeriod: "(2014-2018)",
    primarySchoolDescription: (
      <>
        Ich besuchte die{" "}
        <a href="https://diesterweggs.de" className="diesterweg-grundschule">
          Diesterweg-Grundschule
        </a>{" "}
        in Auerbach, wo ich mein Interesse an Mathematik entdeckte.
      </>
    ),
    secondarySchool: "Gymnasium",
    secondarySchoolPeriod: (monthsUntilGraduation) =>
      `(2018-${monthsUntilGraduation >= 0 ? "jetzt" : "2025"})`,
    secondarySchoolDescription: (
      <>
        Derzeit bin ich am{" "}
        <a href="https://goethegymnasium.com/" className="goethe-gymnasium">
          Goethe-Gymnasium
        </a>{" "}
        in Auerbach.
      </>
    ),
    learnedPython: "Python gelernt",
    learnedPythonPeriod: "(2020)",
    learnedPythonDescription: (
      <>
        Während des Lockdowns 2020 habe ich{" "}
        <a href="https://python.org" className="python">
          Python
        </a>{" "}
        gelernt, was mein Interesse an Programmierung und Softwareentwicklung
        geweckt hat.
      </>
    ),
    learnedJavaScript: "JavaScript gelernt",
    learnedJavaScriptPeriod: "(2023)",
    learnedJavaScriptDescription: (
      <>
        Im Jahr 2023 begann ich,{" "}
        <a href="https://javascript.com" className="javascript">
          JavaScript
        </a>{" "}
        zu lernen, um meine Programmierkenntnisse weiter zu verbessern und die
        Webentwicklung zu erkunden.
      </>
    ),
    internship: "Praktikum (Simba n³, ",
    internshipPeriod: "2024)",
    internshipDescription: (
      <>
        Ich absolvierte ein Praktikum, bei dem ich meine Fähigkeiten in der
        Softwareentwicklung durch die Bewältigung herausfordernder Projekte
        validieren konnte. Mit diesem Praktikum habe ich meine{" "}
        <a href="https://www.java.com/en/" className="java">
          Java
        </a>
        -Kenntnisse verbessert und gelernt, mit{" "}
        <a href="https://www.typescriptlang.org/" className="typescript">
          TypeScript
        </a>{" "}
        und{" "}
        <a href="https://angular.dev/" className="angular">
          Angular.js
        </a>{" "}
        zu arbeiten.
      </>
    ),
    graduation: "Abschluss",
    graduationPeriod: (monthsUntilGraduation) =>
      `${
        monthsUntilGraduation >= 0
          ? `(In ${monthsUntilGraduation} Monaten)`
          : "(Juni 2025)"
      }`,
    graduationDescription: (
      <>
        Ich werde voraussichtlich im Juni 2025 meine Schullaufbahn am Goethe-Gymnasium beenden. Nach meinem
        Abschluss plane ich, mich für eine Ausbildung bei{" "}
        <a href="https://nhochdrei.de" className="simban3">
          Simba n³
        </a>{" "}
        zu bewerben, um meine Fähigkeiten weiter auszubauen und meine berufliche
        Karriere zu beginnen.{" "}
      </>
    ),
  },
};

export default translations;
