import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import "../styles/stack.css";
import Cookies from "js-cookie";

const Stack = () => {
  const [language, setLanguage] = useState(Cookies.get("language") || "en");

  useEffect(() => {
    const interval = setInterval(() => {
      const cookieLanguage = Cookies.get("language");
      if (cookieLanguage && cookieLanguage !== language) {
        setLanguage(cookieLanguage);
      }
    }, 1); // Check every millisecond

    return () => clearInterval(interval);
  }, [language]);

  const stack = {
    "React.js":
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png",
    "Angular.js":
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/1200px-Angular_full_color_logo.svg.png",
    "Tailwind CSS":
      "https://seeklogo.com/images/T/tailwind-css-logo-5AD4175897-seeklogo.com.png",
    "MongoDB": "https://www.svgrepo.com/show/331488/mongodb.svg",
    "Python":
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png",
    "SQL": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/Sqlite-square-icon.svg/1200px-Sqlite-square-icon.svg.png",
    "Node.js":
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/1200px-Node.js_logo.svg.png",
    "Java": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/181_Java_logo_logos-512.png",
    "HTML": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1200px-HTML5_logo_and_wordmark.svg.png",
    "CSS": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1200px-CSS3_logo_and_wordmark.svg.png",
    "JavaScript": "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png",
    "TypeScript": "https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg",
  };

  const stackItems = Object.entries(stack).map(([key, value]) => (
    <div key={key} className="tooltip">
      <img
        src={value}
        alt={`${key} logo`}
        className="logo transition-transform duration-300 ease-in-out"
      />
      <span className="tooltiptext">{key}</span>
    </div>
  ));

  return (
    <div className="bg-gray-800 p-5 rounded-2xl text-center overflow-hidden">
      <h1 className="my-5 text-2xl">
        {language === "en" ? "My Tech Stack" : "Mein Tech Stack"}
      </h1>
      <div className="marquee-wrapper mx-auto">
        <Marquee speed={50} gradient={false} style={{ overflow: "hidden" }}>
          {stackItems}
          {stackItems}
        </Marquee>
      </div>
    </div>
  );
};

export default Stack;