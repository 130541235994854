import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CreationCard from "../components/creationCard";

const Creations = () => {
  return (
    <div className="flex flex-col min-h-screen text-white bg-gray-800">
      <Header />
      <main className="flex-grow p-8">
        <div className="flex items-center justify-center mt-8">
          <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <CreationCard
              image="/assets/listing_bot.webp"
              title="Hypixel Listing Bot"
              description="A bot that helps server owners run their account, coin or any other type of shop."
              imagePosition="right"
              url="https://bots.noemt.dev"
            />
            <CreationCard
              image="/assets/listing_website.webp"
              title="Listing Bot Website"
              description="This website allows you to advertise your shop, even outside of the comforts of discord."
              imagePosition="left"
              url="https://noemt.dev"
            />
            <CreationCard
              image="/assets/portfolio.webp"
              title="This Website"
              description="This is my personal website, where I showcase my projects and skills."
              imagePosition="right"
              url="/"
            />
            <CreationCard
              image="/assets/cloud.webp"
              title="My 'Cloud'"
              description="I made this to quickly upload and access files from anywhere. Currently there are 2 hosted instances."
              imagePosition="right"
              url="https://www.youtube.com/watch?v=rrlSOtuL6eQ"
            />
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Creations;
