import { useState, useMemo, useCallback, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaChevronDown } from "react-icons/fa";
import Cookies from "js-cookie";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState(null);
  const [language, setLanguage] = useState(Cookies.get("language") || "en");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        isOpen &&
        !event.target.closest("#navbar") &&
        !event.target.closest("#menu-button")
      ) {
        setIsOpen(false);
      }
      if (
        dropdownOpen &&
        !event.target.closest("#dropdown") &&
        !event.target.closest("#dropdown-button")
      ) {
        setDropdownOpen(false);
      }
    },
    [isOpen, dropdownOpen]
  );

  const handleLogout = async () => {
    try {
      const response = await fetch("https://api.noemt.dev/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          session_id: Cookies.get("session"),
        }),
      });
      if (response.ok) {
        Cookies.remove("username");
        Cookies.remove("session");
        window.location.reload();
      } else {
        console.error("Failed to log out");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleLanguageToggle = () => {
    const newLanguage = language === "en" ? "de" : "en";
    setLanguage(newLanguage);
    Cookies.set("language", newLanguage, { expires: 365 });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const user = Cookies.get("username");
    if (user) {
      setUsername(user);
    }
  }, []);

  const location = useLocation();

  const navItems = useMemo(
    () => [
      { to: "/", label: "Home" },
      { to: "/creations", label: "Creations" },
      { to: "/login", label: "Sign In" },
      { to: "/dashboard", label: "Dashboard" },
    ],
    []
  );


  return (
    <header className="bg-gray-900 text-white py-4 px-6 flex items-center justify-between mb-4 rounded-b-xl mx-4 relative">
      <div className="flex items-center">
        <img
          src="/assets/icon.webp"
          className="w-10 h-10 md:w-12 md:h-12 mr-4"
          alt="logo"
        />
        <h1 className="text-xl md:text-2xl text-white">noemt studios</h1>
      </div>

      <button
        id="menu-button"
        onClick={() => setIsOpen(!isOpen)}
        className="md:hidden text-white p-3 rounded-md bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500"
        aria-expanded={isOpen}
        aria-controls="mobile-menu"
        aria-label="Toggle navigation menu"
      >
        <FaBars className="text-xl" />
      </button>

      <nav className="hidden md:flex items-center justify-center space-x-4 flex-grow">
        <ul className="flex justify-center items-center space-x-4">
          {navItems.map((item) => (
            <NavItem
              key={item.to}
              to={item.to}
              label={item.label}
              currentPath={location.pathname}
            />
          ))}
        </ul>
      </nav>

      <div className="hidden md:flex items-center space-x-4">
        {username && (
          <div className="relative">
            <button
              id="dropdown-button"
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="flex items-center text-white p-2 rounded-md bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              <span className="mr-2">
                {language === "en"
                  ? `Logged in as ${username}`
                  : `Eingeloggt als ${username}`}
              </span>
              <FaChevronDown />
            </button>
            {dropdownOpen && (
              <div
                id="dropdown"
                className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg py-1 z-50"
              >
                <button
                  onClick={handleLogout}
                  className="text-white p-2 rounded-md bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 w-full text-left"
                >
                  {language === "en" ? "Log Out" : "Ausloggen"}
                </button>
              </div>
            )}
          </div>
        )}
<button
  onClick={handleLanguageToggle}
  className="text-white p-2 rounded-md bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
  disabled={location.pathname !== "/"}
>
  {location.pathname === "/" ? (language === "en" ? "EN" : "DE") : "EN"}
</button>
      </div>
    </header>
  );
};

const NavItem = ({ to, label, currentPath }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    if (to === "/dashboard" && !Cookies.get("session")) {
      event.preventDefault();
      navigate("/login");
    }
  };

  return (
    <li
      className={`navbar-text ${
        currentPath === to ? "text-white" : "text-gray-400"
      }`}
    >
      <NavLink to={to} onClick={handleClick}>
        {label}
      </NavLink>
    </li>
  );
};

export default Header;
